/*
 * @Description: 
 * @Author: wangxiaole
 * @Date: 2024-09-18 11:13:36
 * @LastEditTime: 2024-09-30 14:57:22
 * @LastEditors:  
 */

/**
 * meta.KeepAlive 表示页面留活
 * meta.permissionUrls
 *    1.[]  表示始终有权限
 *    2.null 或 undefined 无权限
 *    3. [xx,xxx] 用户登录查询返回的菜单数据，包含其中一个菜单url，就会动态添加到路由中（否则不能访问该页面）
 */
export default [
  { 
    path: 'marketcode',
    name: 'marketcode',
    component: () => import('../views/marketCode/index.vue'),
    meta: {
      permissionUrls: [],
      keepAlive: true,
      routerType: 2,
    }
  },
  { 
    path: 'markettypecode',
    name: 'markettypecode',
    component: () => import('../views/marketTypeCode/index.vue'),
    meta: {
      permissionUrls: [],
      keepAlive: true,
      routerType: 2,
    }
  },
  { 
    path: 'revenuetype',
    name: 'revenuetype',
    component: () => import('../views/revenuetype/index.vue'),
    meta: {
      permissionUrls: [],
      keepAlive: true,
      routerType: 2,
    }
  },
  
];
